<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<headerNav :isScrolled="isScrolled"></headerNav>
			<div class="pd_banner">
				<div class="pd_banner_img">
					<img
						class="pd_banner_img_1"
						src="https://res.metaera.hk/resources/assets/images/event/pd_banner.png"
						alt=""
					/>
					<img
						class="pd_banner_img_2"
						src="https://res.metaera.hk/resources/assets/images/event/38.png"
						alt=""
					/>
					<div class="pd_banner_img_overlay"></div>
				</div>
				<div class="pd_banner_1">Polkadot</div>
				<div class="pd_banner_2">DECODED</div>
				<div class="pd_banner_3">ASIA 2024</div>
				<div class="pd_banner_4">
					<div class="pd_banner_4_l"> <div class="pd_banner_4_1"> </div> </div>

					<div class="pd_banner_4_r">
						<div class="pd_banner_4_2">SINGAPORE</div>
						<div class="pd_banner_4_3">Sep 16th-17th 2024</div>
						<div class="pd_banner_4_4">
							<div class="pd_banner_4_4_img_1">
								<img
									src="https://res.metaera.hk/resources/assets/images/event/37.png"
									alt=""
								/>
							</div>
							<div
								class="pd_banner_4_4_img_1"
								@click="
									$router.push({
										path: '/',
									})
								"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/logo_w.png"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="pd_banner_bottom"></div>
			</div>
			<div class="pd_tip">
				<div class="pd_tip_line"> </div>

				<div class="pd_tip_line_red">
					<div class="pd_tip_line_red_sub"></div>
				</div>
				<div class="pd_tip_title"> WHAT IS POLKADOT DECODED？ </div>

				<div class="pd_tip_content">
					Polkadot Decoded Asia is the premier event in the Polkadot ecosystem,
					focusing on the dynamic Asian market while attracting a global
					audience. This event brings together developers, investors,
					enthusiasts, and industry leaders to delve into the latest blockchain
					advancements. With keynote speeches, panel discussions, fireside
					chats, AMAs, and exclusive announcements, it offers deep insights into
					Polkadot's groundbreaking innovations and applications. This year's
					Polkadot Decoded Asia will electrify Singapore on September 16-17,
					2024, during the vibrant Singapore Token 2049 week. Don’t miss
					out—join us in Singapore!
				</div>
			</div>
			<div class="pd_data flex">
				<div class="pd_data_sub">
					<div class="pd_data_sub_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/20.png"
							alt=""
						/>
					</div>
					<div class="pd_data_sub_num">2000+</div>
					<div class="pd_data_sub_name">ATTENDEES</div>
				</div>
				<div class="pd_data_sub">
					<div class="pd_data_sub_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/21.png"
							alt=""
						/>
					</div>
					<div class="pd_data_sub_num">100+</div>
					<div class="pd_data_sub_name">SPEAKERS</div> </div
				><div class="pd_data_sub">
					<div class="pd_data_sub_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/18.png"
							alt=""
						/>
					</div>
					<div class="pd_data_sub_num">100+</div>
					<div class="pd_data_sub_name">MEDIA PARTNERS</div> </div
				><div class="pd_data_sub">
					<div class="pd_data_sub_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/19.png"
							alt=""
						/>
					</div>
					<div class="pd_data_sub_num">100+</div>
					<div class="pd_data_sub_name">COUNTRIES</div>
				</div>
			</div>
			<div class="pd_video">
				<div class="pd_video_line"> </div>
				<div class="pd_video_line_red">
					<div class="pd_video_line_red_sub"> </div>
				</div>
				<div class="pd_video_title"> BECOME A SPEAKER </div>

				<div class="pd_video_content_top">
					<div class="pd_video_text_top">
						The event will feature a diverse lineup of speakers from the
						cryptocurrency industry, including developers, investors,
						enthusiasts, and industry leaders. Join us to share your insights
						and connect with the brightest minds.
					</div>
					<div class="pd_video_player_top">
						<video
							ref="videoPlayer_top"
							controls
							autoplay
							muted
							loop
							:poster="posterUrlTop"
							@play="hideOverlayTop"
							@canplay="handleCanPlayTop"
						>
							<source :src="videoUrlTop" type="video/mp4" />
						</video>
						<div v-if="showOverlayTop" class="video-overlay">
							<img
								:src="posterUrlTop"
								@click="playVideoTop"
								class="video-overlay-bg"
							/>
							<img
								src="https://res.metaera.hk/resources/assets/images/event/53.png"
								@click="playVideoTop"
								class="video-overlay-play_top"
							/>
							<div class="bottom-left-container" @click="playVideoYTBTop">
								<div class="info-box">
									<span class="info-text">PLATFORM TO WATCH</span>
									<img
										src="https://res.metaera.hk/resources/assets/images/event/54.png"
										class="info-icon"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<pdintroduceVue></pdintroduceVue>

			<div class="pd_video">
				<div class="pd_video_line"> </div>
				<div class="pd_video_line_red">
					<div class="pd_video_line_red_sub"> </div>
				</div>
				<div class="pd_video_title">
					UNLOCK INNOVATION AND THE FUTURE WITH <br />
					POLKADOT DECODED ASIA 2024
				</div>

				<div class="pd_video_content">
					<div class="pd_video_player">
						<video
							ref="videoPlayer"
							controls
							autoplay
							muted
							loop
							:poster="posterUrl"
							@play="hideOverlay"
							@canplay="handleCanPlay"
						>
							<source :src="videoUrl" type="video/mp4" />
						</video>
						<div v-if="showOverlay" class="video-overlay">
							<img
								:src="posterUrl"
								@click="playVideo"
								class="video-overlay-bg"
							/>
							<img
								src="https://res.metaera.hk/resources/assets/images/event/53.png"
								@click="playVideo"
								class="video-overlay-play"
							/>
							<div class="bottom-left-container" @click="playVideoYTB">
								<div class="info-box">
									<span class="info-text">PLATFORM TO WATCH</span>
									<img
										src="https://res.metaera.hk/resources/assets/images/event/54.png"
										class="info-icon"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="pd_video_text">
						Ready for it? Polkadot Decoded Asia 2024 is coming to Singapore,
						gathering top blockchain experts, developers, and enthusiasts from
						around the world to explore the limitless possibilities of the
						Polkadot ecosystem. Dive into cutting-edge trends, hear from
						industry leaders and innovators, experience the latest blockchain
						technologies firsthand, and participate in interactive sessions
						designed to ignite your creativity. Network with global Web 3.0
						elites and forge deep collaborations that will shape the future.
						Join us for an unforgettable experience!
					</div>
				</div>
			</div>
			<div class="pd_ticket_topline"></div>
			<div class="pd_ticket">
				<div class="pd_ticket_line"> </div>
				<div class="pd_ticket_line_red">
					<div class="pd_ticket_line_red_sub"> </div>
				</div>
				<div class="pd_ticket_title">
					Experience Polkadot Decoded Asia 2024
				</div>

				<div class="pd_ticket_subtitle">
					If you are Polkadot builders and ecosystem contributors, please
					register for the ecosystem pass and wait for the approval.
				</div>
				<div class="pd_ticket_content">
					<div class="pd_ticket_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/56.png"
							alt=""
						/>
					</div>
					<div class="pd_ticket_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/58.png"
							alt=""
						/>
					</div>
				</div>
				<div
					class="pd_ticket_buy pointer"
					@click="showUrl('https://lu.ma/hszom0hw')"
				>
					<img
						:src="currentImage"
						alt=""
						@mouseover="changeImage('b')"
						@mouseleave="changeImage('a')"
					/>
				</div>
			</div>
			<people :isAll="false"></people>
			<div class="pd_badge">
				<div class="pd_badge_bg">
					<img
						class="pd_badge_bg_tl"
						src="https://res.metaera.hk/resources/assets/images/event/image@2x-4.png"
						alt=""
					/>
					<img
						class="pd_badge_bg_tr"
						src="https://res.metaera.hk/resources/assets/images/event/image@2x-3.png"
						alt=""
					/>
					<img
						class="pd_badge_bg_bl"
						src="https://res.metaera.hk/resources/assets/images/event/image@2x.png"
						alt=""
					/>
					<img
						class="pd_badge_bg_br"
						src="https://res.metaera.hk/resources/assets/images/event/image@2x-2.png"
						alt=""
					/>
				</div>
				<div class="pd_badge_line"> </div>
				<div class="pd_badge_line_red">
					<div class="pd_badge_line_red_sub"> </div>
				</div>
				<div class="pd_badge_title"> Claim your DOTphin badge! </div>
				<div class="pd_badge_sub_title">
					Grab your exclusive proof of attendance & start your DoTphin journey.
				</div>
				<div class="pd_badge_btn">
					<div
						class="pd_badge_btn_item"
						@click="showUrl('https://sovereignnature.com/dotphin')"
					>
						CLAIM YOUR BADGE
					</div>
				</div>
				<div class="pd_badge_bottom_title">
					Make waves in conservation today!
				</div>
				<div class="pd_badge_img">
					<img
						src="https://res.metaera.hk/resources/assets/images/event/badge1.png"
						alt=""
					/>
				</div>
			</div>
			<div class="pd_venue">
				<div class="pd_venue_line"> </div>
				<div class="pd_venue_line_red">
					<div class="pd_venue_line_red_sub"> </div>
				</div>
				<div class="pd_venue_title"> SINGAPORE </div>
				<div class="pd_venue_img">
					<div class="scrolling-wrapper row1">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v1.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v2.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v3.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v4.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v5.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v1.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v2.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v3.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v4.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v5.png"
							alt="Image 1"
						/>
					</div>
					<div class="scrolling-wrapper row2">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v6.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v7.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v8.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v9.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v10.png"
							alt="Image 1"
						/><img
							src="https://res.metaera.hk/resources/assets/images/event/v6.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v7.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v8.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v9.png"
							alt="Image 1"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/v10.png"
							alt="Image 1"
						/>
					</div>
				</div>
			</div>
			<div class="pd_venue_mobile">
				<div class="pd_venue_content_mobile">
					<div class="pd_venue_name_mobile">SINGAPORE</div>
					<div class="pd_venue_text_mobile"
						>Conrad Singapore Orchard Hotel, Singapore 1 Cuscaden Rd, Singapore
						249715
					</div>
					<div class="pd_venue_mobile_img">
						<el-carousel :interval="3000" arrow="always">
							<el-carousel-item v-for="(item, index) in items" :key="index">
								<div class="image-wrapper">
									<img :src="item.src" :alt="item.alt" class="carousel-image" />
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
			<div class="pd_moment">
				<div class="pd_moment_line"> </div>
				<div class="pd_moment_line_red">
					<div class="pd_moment_line_red_sub"> </div>
				</div>
				<div class="pd_moment_title"> HIGHLIGHTS </div>

				<div class="pd_moment_content">
					<div class="pd_moment_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/m7.png"
							alt=""
						/>
					</div>
					<div class="pd_moment_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/m3.png"
							alt=""
						/>
					</div>
				</div>
				<div class="pd_moment_content_s">
					<div class="pd_moment_s_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/m4.png"
							alt=""
						/>
					</div>
					<div class="pd_moment_s_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/m5.png"
							alt=""
						/>
					</div>
					<div class="pd_moment_s_img">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/m6.png"
							alt=""
						/>
					</div>
				</div>
			</div>
			<bottom></bottom>
			<div class="demo" style="height: 50px"></div>
		</div>
	</div>
</template>

<script>
import headerNav from "./headern.vue";
import bottom from "./pdbottom.vue";
import pdintroduceVue from "./pdintroduce.vue";
import people from "./pdpeople.vue";

export default {
	name: "",
	components: {
		headerNav,
		bottom,
		people,
		pdintroduceVue,
	},
	data() {
		return {
			osafai: false,
			olead: false,
			isScrolled: false,
			videoUrl:
				"https://res.metaera.hk/resources/assets/images/event/polkadotvideo3.mp4",
			posterUrl: "https://res.metaera.hk/resources/assets/images/event/vp2.png",
			showOverlay: true,

			videoUrlTop:
				"https://res.metaera.hk/resources/assets/images/event/speakervideo3.mp4",
			posterUrlTop:
				"https://res.metaera.hk/resources/assets/images/event/svp2.png",
			showOverlayTop: true,
			items: [
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v1.png",
					alt: "Image 1",
				},
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v2.png",
					alt: "Image 2",
				},
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v3.png",
					alt: "Image 2",
				},
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v4.png",
					alt: "Image 2",
				},
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v5.png",
					alt: "Image 2",
				},
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v10.png",
					alt: "Image 1",
				},
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v6.png",
					alt: "Image 2",
				},
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v7.png",
					alt: "Image 2",
				},
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v8.png",
					alt: "Image 2",
				},
				{
					src: "https://res.metaera.hk/resources/assets/images/event/v9.png",
					alt: "Image 2",
				},
			],
			currentImage:
				"https://res.metaera.hk/resources/assets/images/event/pd_buy.png", // 初始图片
			images: {
				a: "https://res.metaera.hk/resources/assets/images/event/pd_buy.png", // 图片A
				b: "https://res.metaera.hk/resources/assets/images/event/pd_buy1.png", // 图片B
			},
		};
	},
	created() {},
	methods: {
		handleScroll(event) {
			this.isScrolled = event.target.scrollTop > 100;
		},
		showUrl(url) {
			window.open(url, "");
		},
		changeImage(imageKey) {
			this.currentImage = this.images[imageKey];
		},
		playVideo() {
			const video = this.$refs.videoPlayer;
			video.play();
		},
		playVideoYTB() {
			window.open("https://www.youtube.com/watch?v=67e2YOZ5XKg", "");
		},
		hideOverlay() {
			this.showOverlay = false;
		},
		playVideoTop() {
			this.$refs.videoPlayer_top.play();
		},
		playVideoYTBTop() {
			window.open("https://www.youtube.com/watch?v=dlj783PYR5A", "");
		},
		hideOverlayTop() {
			this.showOverlayTop = false;
		},
		handleCanPlay() {
			// 尝试自动播放
			this.$refs.videoPlayer.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
		},
		handleCanPlayTop() {
			// 尝试自动播放
			this.$refs.videoPlayer_top.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
		},
		mounted() {
			// 确保视频在加载完毕后自动播放
			this.$refs.videoPlayer.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
			this.$refs.videoPlayer_top.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: #f7f8f9;
}
</style>

<!-- banner -->
<style scoped>
.pd_banner {
	margin-top: 0px;
	width: 100vw;
	height: calc(100vw * (2100 / 3840));
}

.pd_banner_img {
	width: 100%;
	height: auto;
}

.pd_banner_img_1 {
	display: flex;
}

.pd_banner_img_2 {
	display: none;
}
.pd_banner_img img {
	width: 100%;
	height: 100%;
}
.pd_banner_bottom {
	position: absolute;
	margin-left: 5%;
	margin-right: 5%;
	margin-top: -40px;
	height: 43px;
	width: 90%;
	background: white;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	z-index: 10;
}

.pd_banner_1 {
	position: absolute;
	left: calc(100vw * 90 / 1280);
	top: calc(100vw * 120 / 1280);
	font-family: Bison-Bold;
	font-size: calc(100vw * 160 / 1280);
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.pd_banner_2 {
	position: absolute;
	left: calc(100vw * 90 / 1280);
	top: calc(100vw * 320 / 1280);
	padding-left: 20px;
	padding-right: 20px;
	font-family: Bison-Bold;
	font-size: calc(100vw * 120 / 1280);
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	background: #e6007a;
}

.pd_banner_3 {
	position: absolute;
	left: calc(100vw * 480 / 1280);
	top: calc(100vw * 320 / 1280);
	font-family: Bison-Bold;
	font-size: calc(100vw * 120 / 1280);
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.pd_banner_4 {
	position: absolute;
	display: flex;
	width: calc(100vw * 900 / 1280);
	height: calc(100vw * 100 / 1280);
	left: calc(100vw * 90 / 1280);
	top: calc(100vw * 520 / 1280);
}
.pd_banner_4_l {
	width: 2px;
	height: 100%;
	opacity: 1;
	background: #ffffff;
}
.pd_banner_4_1 {
}
.pd_banner_4_r {
	width: calc(100vw * 600 / 1280);
}
.pd_banner_4_2 {
	margin-left: calc(100vw * 20 / 1280);
	font-family: DINPro-Bold;
	font-size: calc(100vw * 24 / 1280);
	font-weight: bold;
	line-height: calc(100vw * 30 / 1280);
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.pd_banner_4_3 {
	margin-left: calc(100vw * 20 / 1280);
	font-family: D-DIN;
	font-size: calc(100vw * 16 / 1280);
	font-weight: normal;
	line-height: calc(100vw * 40 / 1280);
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}

.pd_banner_4_4 {
	margin-left: calc(100vw * 16 / 1280);
	display: flex;
}
.pd_banner_4_4_img_1 {
	margin-right: calc(100vw * 20 / 1280);
	width: calc(100vw * 82 / 1280);
	height: calc(100vw * 24 / 1280);
	align-content: center;
	align-items: center;
	text-align: center;
}
.pd_banner_4_4_img_1 img {
	width: 100%;
	height: auto;
}

.pd_banner_img_overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100vw * (2100 / 3840));
	opacity: 0.3;
	background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 99%);
}

@media screen and (max-width: 900px) {
	.pd_banner_img_overlay {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		height: calc(100vw * 590 / 375);
		opacity: 0.3;
		background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 99%);
	}
	.pd_banner {
		margin-top: 50px;
		height: calc(100vw * 590 / 375);
	}
	.pd_banner_img {
		width: 100%;
		height: 100%;
	}

	.pd_banner_img_1 {
		display: none;
	}

	.pd_banner_img_2 {
		display: flex;
	}

	.pd_banner_1 {
		position: absolute;
		left: calc(100vw * 30 / 375);
		top: calc(100vw * 75 / 375);
		font-size: calc(100vw * 80 / 375);
		line-height: calc(100vw * 80 / 375);
	}

	.pd_banner_2 {
		position: absolute;
		left: calc(100vw * 30 / 375);
		top: calc(100vw * 165 / 375);
		font-size: calc(100vw * 70 / 375);
		line-height: 1;
	}

	.pd_banner_3 {
		position: absolute;
		left: calc(100vw * 30 / 375);
		width: calc(100vw * 50 / 375);
		top: calc(100vw * 260 / 375);
		font-family: Bison-Bold;
		font-size: calc(100vw * 60 / 375);
		display: flex;
		line-height: 0.9;
		align-items: center;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}

	.pd_banner_4 {
		position: absolute;
		display: flex;
		width: calc(100vw * 300 / 375);
		height: calc(100vw * 100 / 375);
		left: calc(100vw * 30 / 375);
		top: calc(100vw * 420 / 375);
	}
	.pd_banner_4_l {
		width: 2px;
		height: 100%;
		opacity: 1;
		background: #ffffff;
	}
	.pd_banner_4_r {
		width: calc(100vw * 300 / 375);
	}
	.pd_banner_4_2 {
		margin-left: calc(100vw * 20 / 375);
		font-family: DINPro-Bold;
		font-size: calc(100vw * 24 / 375);
		font-weight: bold;
		line-height: calc(100vw * 30 / 375);
		display: flex;
		align-items: center;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.pd_banner_4_3 {
		margin-left: calc(100vw * 20 / 375);
		font-family: D-DIN;
		font-size: calc(100vw * 16 / 375);
		font-weight: normal;
		line-height: calc(100vw * 40 / 375);
		display: flex;
		align-items: center;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.pd_banner_4_4 {
		margin-left: calc(100vw * 16 / 375);
		display: flex;
	}
	.pd_banner_4_4_img_1 {
		margin-right: calc(100vw * 20 / 375);
		width: calc(100vw * 82 / 375);
		height: calc(100vw * 24 / 375);
	}
}
</style>

<!-- tip -->
<style scoped>
/* tip */
.pd_tip {
	margin-left: 5%;
	margin-right: 5%;
	padding-top: 20px;
	padding-bottom: 60px;
	background: white;
	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 30px;
}
.pd_tip_line {
	margin-left: 60px;
	margin-right: 60px;
	height: 1px;
	opacity: 1;
	background: #e7e7e7;
}
.pd_tip_line_red {
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中，如果需要的话 */
}

.pd_tip_line_red_sub {
	margin-top: -1px;
	width: 80px;
	height: 2px;
	opacity: 1;
	background: #e6007a;
}
.pd_tip_title {
	margin-top: 30px;
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	text-align: center;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
.pd_tip_content {
	margin-top: 20px;
	margin-left: 30px;
	margin-right: 30px;
	font-family: DINPro-Regular, DINPro;
	font-size: 22px;
	font-weight: normal;
	line-height: 30px;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
@media screen and (max-width: 900px) {
	.pd_tip {
		padding-top: 0px;
		padding-bottom: 30px;
	}
	.pd_tip_title {
		font-size: 20px;
	}
	.pd_tip_content {
		font-size: 14px;
	}
}
</style>

<!-- pd_data -->
<style scoped>
/* pd_data */
.pd_data {
	height: 400px;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 0px;
}
.pd_data_sub {
	flex: 1 0 25%;
	box-sizing: border-box;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.pd_data_sub_img {
	margin-top: 80px;
	width: 70px;
	height: 70px;
	align-content: center;
	align-items: center;
}
.pd_data_sub_img img {
	width: 100%;
	height: auto;
}
.pd_data_sub_num {
	margin-top: 25px;
	height: 54px;
	opacity: 1;
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	color: #333333;
}
.pd_data_sub_name {
	margin-top: 30px;
	height: 27px;
	opacity: 1;
	font-family: DINPro-Regular, DINPro;
	font-size: 22px;
	font-weight: normal;
	line-height: 30px;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	color: #333333;
}

@media screen and (max-width: 900px) {
	.pd_data {
		height: 500px;
		width: 100%;
		flex-wrap: wrap;
		margin-bottom: 20px;
	}
	.pd_data_sub {
		flex: 1 0 50%;
		height: 250px;
	}
	.pd_data_sub_img {
		margin-top: 40px;
	}
	.pd_data_sub_num {
		margin-top: 25px;
		font-size: 26px;
	}
	.pd_data_sub_name {
		margin-top: 0px;
		height: 27px;
		font-size: 18px;
	}
}
</style>

<!-- pd_video -->
<style scoped>
/* pd_video */
.pd_video {
	width: 100%;
	background: #e6007a;
	padding-top: 48px;
	padding-bottom: 0px;
}
.pd_video_line {
	margin-left: 150px;
	margin-right: 150px;
	height: 1px;
	opacity: 1;
	background: #e7e7e7;
}
.pd_video_line_red {
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中，如果需要的话 */
}

.pd_video_line_red_sub {
	margin-top: -1px;
	width: 80px;
	height: 2px;
	opacity: 1;
	background: #ffffff;
}

.pd_video_title {
	margin-top: 43px;
	margin-left: 120px;
	margin-right: 120px;
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.pd_video_content {
	width: 100%;
	padding-bottom: 80px;
	padding-top: 70px;
	display: flex;
	align-items: center; /* 可以根据需要调整对齐方式 */
}
.pd_video_content_top {
	width: 100%;
	padding-bottom: 80px;
	padding-top: 70px;
	display: contents;
	align-items: center;
}
.pd_video_player {
	position: relative;
	display: inline-block;
	margin-left: 10%;
	width: 500px;
	background: black;
	border-radius: 10px;
}
.pd_video_player_top {
	position: relative;
	display: inline-block;
	width: 80%;
	margin-top: 30px;
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 30px;
}
.video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	border-radius: 10px;
}

.video-overlay-bg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
	border-radius: 10px;
}
.bottom-left-container {
	position: absolute;
	bottom: 25px; /* 你可以根据需要调整位置 */
	left: 0px; /* 你可以根据需要调整位置 */
	border-radius: 0px 10px 10px 0px;
	background: rgba(0, 0, 0, 0.6);
}
.info-box {
	display: flex;
	align-items: center;
}
.info-text {
	margin-left: 15px;
	margin-right: 15px;
	margin-top: 15px;
	margin-bottom: 15px;
	font-family: DINPro-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.info-icon {
	margin-right: 15px;
	width: auto;
	height: 20px;
}
.video-overlay-play {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 70px;
	height: auto;
	transform: translate(-50%, -50%);
}
.video-overlay-play_top {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100px;
	height: auto;
	transform: translate(-50%, -50%);
}
.pd_video_player video {
	width: 100%;
	border-radius: 10px;
}
.pd_video_player_top video {
	width: 100%;
	border-radius: 10px;
}
.pd_video_text {
	width: 50%;
	padding-left: 6%;
	padding-right: 6%;
	font-family: DINPro-Regular, DINPro;
	font-size: 24px;
	font-weight: normal;
	line-height: 30px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	color: #ffffff;
	text-align: justify;
	/* background: white; */
}
.pd_video_text_top {
	width: auto;
	padding-left: 0;
	padding-right: 0;
	margin-top: 30px;
	margin-left: 5%;
	margin-right: 5%;
	font-family: DINPro-Regular, DINPro;
	font-size: 24px;
	font-weight: normal;
	line-height: 30px;
	/* display: flex; */
	align-items: center;
	letter-spacing: 0em;
	color: #ffffff;
	text-align: center;
}
@media screen and (max-width: 900px) {
	.pd_video {
		padding-top: 28px;
		padding-bottom: 40px;
	}
	.pd_video_text_top {
		margin-left: 30px;
		margin-right: 30px;
		font-size: 14px;
	}
	.pd_video_line {
		margin-left: 20px;
		margin-right: 20px;
		height: 1px;
		opacity: 1;
		background: #e7e7e7;
	}
	.pd_video_title {
		margin-top: 18px;
		margin-left: 23px;
		margin-right: 23px;
		font-family: DINPro-Bold;
		font-size: 24px;
	}
	.pd_video_content {
		width: 100%;
		padding-bottom: 80px;
		padding-top: 70px;
		display: contents;
		align-items: center;
	}
	.pd_video_player {
		width: auto;
		margin-top: 30px;
		margin-left: 30px;
		margin-right: 30px;
	}
	.pd_video_player video {
		width: 100%;
		border-radius: 10px;
	}
	.pd_video_text {
		width: auto;
		padding-left: 0;
		padding-right: 0;
		margin-top: 30px;
		margin-left: 30px;
		margin-right: 30px;
		font-size: 14px;
	}
	.video-overlay-play_top {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 70px;
		height: auto;
		transform: translate(-50%, -50%);
	}
	.pd_video_player_top {
		margin-bottom: 0px;
	}

	.bottom-left-container {
		position: absolute;
		bottom: 15px; /* 你可以根据需要调整位置 */
		left: 0px; /* 你可以根据需要调整位置 */
		border-radius: 0px 10px 10px 0px;
		background: rgba(0, 0, 0, 0.6);
	}
	.info-box {
		display: flex;
		align-items: center;
	}
	.info-text {
		margin-left: 10px;
		margin-right: 6px;
		margin-top: 10px;
		margin-bottom: 10px;
		font-family: DINPro-Bold;
		font-size: 12px;
		font-weight: bold;
		line-height: normal;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.info-icon {
		margin-right: 10px;
		width: auto;
		height: 14px;
	}
}
</style>

<!-- pd_ticket -->
<style scoped>
/* pd_video */
.pd_ticket_topline {
	display: none;
}
.pd_ticket {
	width: 100%;
	background: #1c0b3f;
	padding-top: 48px;
}
.pd_ticket_line {
	margin-left: 150px;
	margin-right: 150px;
	height: 1px;
	opacity: 1;
	background: #e7e7e7;
}
.pd_ticket_line_red {
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中，如果需要的话 */
}

.pd_ticket_line_red_sub {
	margin-top: -1px;
	width: 80px;
	height: 2px;
	opacity: 1;
	background: #ffffff;
}

.pd_ticket_title {
	margin-top: 43px;
	margin-left: 120px;
	margin-right: 120px;
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.pd_ticket_subtitle {
	margin-top: 30px;
	font-family: DINPro-Regular, DINPro;
	font-size: 24px;
	font-weight: normal;
	line-height: 30px;
	align-content: center;
	align-items: center;
	text-align: center;
	letter-spacing: 0em;
	margin-left: 30px;
	margin-right: 30px;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.pd_ticket_content {
	width: 100%;
	padding-bottom: 44px;
	padding-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pd_ticket_img {
	width: 430px;
	height: auto;
	margin-right: 15px;
	margin-left: 15px;
}
.pd_ticket_img img {
	width: 100%;
	height: auto;
}
.pd_ticket_buy {
	padding-bottom: 66px;
	width: 100%;
	height: 50px;
	/* display: flex; */
	justify-content: center;
	align-items: center;
	text-align: center;
}
.pd_ticket_buy img {
	width: 170px;
	height: 50px;
}
.pd_ticket_buy {
	margin-top: 0px;
}
@media screen and (max-width: 900px) {
	.pd_ticket_topline {
		display: flex;
		margin-top: 30px;
	}
	.pd_ticket {
		width: 100%;
		background: #1c0b3f;
		padding-top: 28px;
	}
	.pd_ticket_line {
		margin-left: 20px;
		margin-right: 20px;
	}
	.pd_ticket_title {
		margin-top: 20px;
		margin-left: 30px;
		margin-right: 20px;
		font-size: 24px;
	}
	.pd_ticket_subtitle {
		margin-top: 10px;
		margin-left: 30px;
		margin-right: 30px;
		font-size: 14px;
	}
	.pd_ticket_content {
		width: 100%;
		padding-bottom: 44px;
		padding-top: 50px;
		display: contents;
		justify-content: center;
		align-items: center;
	}
	.pd_ticket_img {
		width: auto;
		margin-left: 40px;
		margin-right: 40px;
		height: auto;
	}
	.pd_ticket_buy {
		margin-top: 30px;
		padding-bottom: 30px;
	}
}
</style>

<!-- pd_badge -->
<style scoped>
.pd_badge {
	background: #ffffff;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-top: 60px;
	position: relative;
}

.pd_badge_bg img {
	position: absolute;
}

.pd_badge_bg_tl {
	top: 0;
	left: 10%;
	height: 148px;
	width: auto;
}

.pd_badge_bg_tr {
	top: 0%;
	right: 10%;
	height: 120px;
	width: auto;
}

.pd_badge_bg_bl {
	bottom: 3%;
	left: 15%;
	height: 147px;
	width: auto;
}

.pd_badge_bg_br {
	bottom: 0;
	right: 10%;
	height: 160px;
	width: auto;
}
.pd_badge_line {
	margin-left: 150px;
	margin-right: 150px;
	height: 1px;
	opacity: 1;
	background: #e7e7e7;
}
.pd_badge_line_red {
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中，如果需要的话 */
}

.pd_badge_line_red_sub {
	margin-top: -1px;
	width: 80px;
	height: 2px;
	opacity: 1;
	background: #e6007a;
}

.pd_badge_title {
	margin-top: 43px;
	margin-left: 120px;
	margin-right: 120px;
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}

.pd_badge_sub_title {
	margin-top: 43px;
	margin-left: 10%;
	margin-right: 10%;
	font-family: DINPro-Regular, DINPro;
	font-size: 24px;
	font-weight: normal;
	line-height: 30px;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}

.pd_badge_bottom_title {
	margin-top: 38px;
	margin-left: 10%;
	margin-right: 10%;
	font-family: DINPro-Regular, DINPro;
	font-size: 18px;
	font-weight: normal;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}

.pd_badge_btn {
	width: 100%;
	margin-top: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.pd_badge_btn_item {
	background: #2dfda9;
	width: 270px;
	height: 50px;
	border-radius: 25px;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	font-family: DINPro-Bold;
	font-size: 20px;
	font-weight: bold;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	color: #333333;
}
.pd_badge_img {
	margin-top: 36px;
	padding-bottom: 50px;
}
.pd_badge_img img {
	height: 70px;
	width: auto;
}

@media screen and (max-width: 900px) {
	.pd_badge {
		padding-top: 40px;
	}
	.pd_badge_line {
		margin-left: 20px;
		margin-right: 20px;
	}
	.pd_badge_title {
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		font-size: 24px;
	}

	.pd_badge_sub_title {
		margin-top: 10px;
		font-size: 14px;
	}

	.pd_badge_btn {
		margin-top: 20px;
	}

	.pd_badge_btn_item {
		width: 230px;
		height: 36px;
		border-radius: 18px;
		font-size: 14px;
		line-height: 16px;
	}
	.pd_badge_bottom_title {
		margin-top: 18px;
		font-size: 14px;
	}
	.pd_badge_img {
		margin-top: 20px;
		padding-bottom: 30px;
	}
	.pd_badge_img img {
		height: 40px;
		width: auto;
	}

	.pd_badge_bg_tl {
		top: 0;
		left: 0%;
		height: 80px;
		width: auto;
	}

	.pd_badge_bg_tr {
		top: 0%;
		right: 0%;
		height: 80px;
		width: auto;
	}

	.pd_badge_bg_bl {
		bottom: 3%;
		left: 5%;
		height: 80px;
		width: auto;
	}

	.pd_badge_bg_br {
		bottom: 0;
		right: 0%;
		height: 90px;
		width: auto;
	}
}
</style>

<!-- pd_venue -->
<style scoped>
.pd_venue_mobile {
	display: none;
}
.pd_venue {
	background: #1c0b3f;
	justify-content: center;
	align-items: center;
	padding-left: 75px;
	padding-right: 75px;
	padding-top: 60px;
}
.pd_venue_line {
	margin-left: 150px;
	margin-right: 150px;
	height: 1px;
	opacity: 1;
	background: #e7e7e7;
}
.pd_venue_line_red {
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中，如果需要的话 */
}

.pd_venue_line_red_sub {
	margin-top: -1px;
	width: 80px;
	height: 2px;
	opacity: 1;
	background: #ffffff;
}

.pd_venue_title {
	margin-top: 43px;
	margin-left: 120px;
	margin-right: 120px;
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.pd_venue_img {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	padding-top: 30px;
	padding-bottom: 80px;
}
.scrolling-wrapper {
	display: flex;
	animation: scroll 10s linear infinite;
}

.scrolling-wrapper img {
	width: 20%; /* Adjust the width as needed */
	height: auto;
	margin: 1%;
}
.scrolling-wrapper.row1 {
	animation: scroll1 10s linear infinite;
}

.scrolling-wrapper.row2 {
	animation: scroll2 15s linear infinite;
}

@keyframes scroll1 {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-110%);
	}
}

@keyframes scroll2 {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-110%);
	}
}
@media screen and (max-width: 900px) {
	.pd_venue {
		display: none;
	}
	.pd_venue_mobile {
		display: block;
		background: #1c0b3f;
		justify-content: center;
		align-items: center;
		padding-left: 10vw;
		padding-right: 10vw;
	}
	.pd_venue_content_mobile {
		align-items: center;
		text-align: center;
		padding-top: 30px;
	}

	.pd_venue_name_mobile {
		font-family: DINPro-Bold;
		font-size: 24px;
		font-weight: bold;
		line-height: 36px;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}
	.pd_venue_text_mobile {
		margin-top: 10px;
		font-family: DINPro-Regular, DINPro;
		font-size: 18px;
		font-weight: normal;
		line-height: 29px;
		display: flex;
		align-items: center;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}
	.pd_venue_mobile_img {
		margin-top: 40px;
		width: 100%;
		padding-bottom: 50px;
	}
	.carousel-container {
		width: 100%;
		height: auto;
		max-width: 600px; /* 设置轮播容器的最大宽度 */
		margin: 0 auto; /* 居中对齐 */
		overflow: hidden; /* 确保多余的部分不显示 */
	}

	.image-wrapper {
		position: relative;
		padding-bottom: 66.67%;
		overflow: hidden;
		border-radius: 20px;
	}

	.carousel-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain; /* 确保图片在容器中完整显示 */
		display: block;
		border-radius: 20px;
	}
}
</style>

<!-- pd_moment -->
<style scoped>
.pd_moment {
	width: 100%;
	padding-top: 48px;
	background: white;
}
.pd_moment_line {
	margin-left: 150px;
	margin-right: 150px;
	height: 1px;
	opacity: 1;
	background: #e7e7e7;
}
.pd_moment_line_red {
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中，如果需要的话 */
}

.pd_moment_line_red_sub {
	margin-top: -1px;
	width: 80px;
	height: 2px;
	opacity: 1;
	background: #e6007a;
}

.pd_moment_title {
	margin-top: 43px;
	margin-left: 120px;
	margin-right: 120px;
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
.pd_moment_subtitle {
	margin-top: 30px;
	font-family: DINPro-Regular, DINPro;
	font-size: 24px;
	font-weight: normal;
	line-height: 30px;
	align-content: center;
	align-items: center;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
.pd_moment_content {
	margin-left: 30px;
	margin-right: 30px;
	padding-top: 50px;
	display: flex;
	justify-content: space-between;
}
.pd_moment_img {
	width: 100%;
	margin-right: 20px;
	margin-left: 20px;
}
.pd_moment_img img {
	width: 100%;
	height: auto;
}
.pd_moment_content_s {
	margin-left: 40px;
	margin-right: 40px;
	padding-bottom: 44px;
	padding-top: 30px;
	display: flex;
	justify-content: space-between;
}
.pd_moment_s_img {
	width: 100%;
	margin-right: 10px;
	margin-left: 10px;
}
.pd_moment_s_img img {
	width: 100%;
	height: auto;
}
@media screen and (max-width: 900px) {
	.pd_moment_line {
		margin-left: 20px;
		margin-right: 20px;
	}
	.pd_moment_title {
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		font-family: DINPro-Bold;
		font-size: 24px;
	}
	.pd_moment_subtitle {
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		font-size: 18px;
	}
	.pd_moment_content {
		padding-top: 30px;
		margin-left: 10px;
		margin-right: 10px;
	}
	.pd_moment_img {
		height: auto;
		margin-right: 5px;
		margin-left: 5px;
	}
	.pd_moment_content_s {
		margin-left: 10px;
		margin-right: 10px;
		padding-top: 10px;
	}
	.pd_moment_s_img {
		height: auto;
		margin-right: 5px;
		margin-left: 5px;
	}
}
</style>

<!-- 移动端 -->
<style scoped>
@media screen and (max-width: 900px) {
}
</style>

<style>
.pd_venue_img .el-carousel__arrow {
	margin-top: -20px !important;
}
.pd_venue_mobile_img .el-carousel__arrow {
	margin-top: -20px !important;
}
.el-carousel__indicators--horizontal {
	left: 30%;
	transform: translateX(-10%);
}
.el-carousel__button {
	display: flex;
	margin: 0 0px;
	width: 8px;
}
</style>
